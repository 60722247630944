import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LineworkItem from "./LineworkItem";
import { Box, Divider, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import Button from "../Common/Button";
import { getLineworkListAction } from "redux/slices/linework/list";
import styled from 'styled-components';

const StyledGrid = styled(Grid)`
  margin: 10px !important;
`;

const LineworkList = () => {
  const linework = useSelector((state) => state?.lineworkList?.data);
  const flights = useSelector((state) => state?.flightList?.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLineworkListAction());
  }, [dispatch]);

  useEffect(() => {
    // Cleanup function to remove linework layers
    return () => {
      const map = window.map;
      if (map) {
        map?.getStyle()?.layers?.forEach(layer => {
          if (layer.id.startsWith('linework')) {
            map?.removeLayer(layer.id);
          }
        });
        map?.getStyle?.()?.sources?.forEach(source => {
          if (source.startsWith('linework')) {
            map.removeSource(source);
          }
        });
      }
    };
  }, []);

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        pt={2}
        pb={2}
      >
        <Typography variant="h4">Lineworks</Typography>
        <Box pt={2}>
          <Button
            label="Add New Linework"
            size="small"
            sx={{
              height: 37,
            }}
            component={NavLink}
            to="/linework/add"
          />
        </Box>
      </Box>
      <Divider />

      {linework ? linework?.map((lw, index) => {
        return (
          <StyledGrid item xs key={lw.id}>
            <LineworkItem LineworkItem={lw} />
          </StyledGrid>
        );
      })
        : (
          <Typography align="center" mt={4}>
            No linework Found
          </Typography>
        )}
      {/*<MaterialItem/>*/}
    </>
  );
};

export default LineworkList;
