import { createSlice } from '@reduxjs/toolkit';

const lineworkListSlice = createSlice({
  name: 'lineworkList',
  initialState: {
    data: [],
    loading: false,
    error: null,
    visibleLayers: {}, // Initialize visibleLayers as an empty object
    selectedLinework: null,
    selectedId: null
  },
  reducers: {
    getLineworkListAction: (state) => {
      state.loading = true;
    },
    setLineworkListAction: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    setLineworkListError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    toggleLineworkVisibility: (state, action) => {
      const id = action.payload.id;
      state.visibleLayers[id] = !state.visibleLayers[id];
    },
    setSelectedLinework: (state, action) => {
      state.selectedLinework = action.payload;
    },
    deleteLineworkAction: (state, action) => {
      state.loading = true;
    },
    setDeleteLineworkIdAction(state, action) {
      return {
        ...state,
        selectedId: action.payload.id,
      };
    },
    resetDeleteLineworkAction: (state) => {
      state.loading = false;
    },
  },
});

export const {
  getLineworkListAction,
  setLineworkListAction,
  setLineworkListError,
  requestToggleLineworkVisibility,
  toggleLineworkVisibility,
  setSelectedLinework,
  deleteLineworkAction,
  setDeleteLineworkIdAction,
  resetDeleteLineworkAction
} = lineworkListSlice.actions;

export default lineworkListSlice.reducer;
