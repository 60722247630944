import { call, put, select } from "redux-saga/effects";
import { getLineworkListAction, setLineworkListAction, setLineworkListError, resetDeleteLineworkAction } from "redux/slices/linework/list";
import { resetLineworkFileAction, setLineworkCreateError } from "redux/slices/linework/create";
import { getLineworkListRequest, createLineworkRequest, getLineworkKLRequest, deleteLineworkKLRequest } from "redux/sagas/requests/lineworkRequest";
import mapboxgl from "mapbox-gl";

export function* getLineworkListHandler() {
  try {
    const map = window.map;
    const selectedProjectuuId = yield select((state) => state.selectedProject.uuid);
    const response = yield call(getLineworkListRequest, selectedProjectuuId);
    const lineworkList = response?.data?.data;

    yield put(setLineworkListAction(lineworkList));

    if (lineworkList && lineworkList.length > 0) {
      for (const linework of lineworkList) {
        const kmzUrl = linework.file;
        const geojson = yield call(getLineworkKLRequest, linework?.uuid);

        geojson.data.features.forEach(feature => {
          if (feature.properties && feature.properties.color) {
            feature.properties.color = convertColor(feature.properties.color);
          }
        });

        const baseId = 'linework';
        const sourceId = `${baseId}-source-${linework?.uuid}`;
        const pointLayerId = `${baseId}-point-${linework?.uuid}`;
        const lineLayerId = `${baseId}-line-${linework?.uuid}`;
        const polygonLayerId = `${baseId}-polygon-${linework?.uuid}`;

        map.addSource(sourceId, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: geojson?.data?.features || []
          }
        });

        map.addLayer({
          id: polygonLayerId,
          type: 'fill',
          source: sourceId,
          paint: {
            'fill-color': ['get', 'color'],
            'fill-opacity': 0.6
          },
          filter: ['==', '$type', 'Polygon']
        });

        map.addLayer({
          id: lineLayerId,
          type: 'line',
          source: sourceId,
          paint: {
            'line-color': ['get', 'color'],
            'line-width': 5
          },
          filter: ['==', '$type', 'LineString']
        });

        map.addLayer({
          id: pointLayerId,
          type: 'circle',
          source: sourceId,
          paint: {
            'circle-radius': 5,
            'circle-color': ['get', 'color'],
          },
          filter: ['==', '$type', 'Point']
        });

        let currentPopup = null;

        const showFeatureInfo = (e) => {
          const features = map.queryRenderedFeatures(e.point, {
            layers: [pointLayerId, polygonLayerId, lineLayerId]
          });
          if (features.length > 0) {
            const feature = features[0];
            const properties = feature.properties;
            const popupContent = Object.keys(properties).map(key => `<strong>${key}:</strong> ${properties[key]}`).join('<br>');

            new mapboxgl.Popup()
              .setLngLat(e.lngLat)
              .setHTML(`${popupContent}`)
              .addTo(map);
          }
        };

        map.on('click', pointLayerId, showFeatureInfo);
        map.on('click', polygonLayerId, showFeatureInfo);
        map.on('click', lineLayerId, showFeatureInfo);
      }
    }
  } catch (error) {
    yield put(setLineworkListError(error.message));
  }
}




const getNextLayerId = (baseId) => {
  let counter = 1;
  const map = window.map;
  while (map.getLayer(`${baseId}${counter}`)) {
    counter++;
  }
  return `${baseId}${counter}`;
}

const convertColor = (hexColor) => {
  const hex = hexColor.slice(0, 6); // 'ff0000' -> 'ff0000' (remove alpha part if any)
  return `#${hex}`; // Convert to #RRGGBB
}

export function* getLineworkVisibilityHandler(action) {
  try {
    const { id, uuid } = action.payload;
    const visibility = yield select(state => state.lineworkList?.visibleLayers?.[id]);
    const map = window.map;

    const layers = [
      `linework-line-${uuid}`,
      `linework-point-${uuid}`,
      `linework-polygon-${uuid}`
    ];

    layers.forEach(layerId => {
      if (map.getLayer(layerId)) {
        const newVisibility = visibility ? 'none' : 'visible';
        map.setLayoutProperty(layerId, 'visibility', newVisibility);
      }
    });
  } catch (error) {
    console.error('Error handling visibility:', error);
  }
}


export function* createLineworkHandler(action) {
  try {
    const { history, formData } = action.payload;
    const response = yield call(createLineworkRequest, formData);
    history.push("/linework");
    yield call(getLineworkListAction());

    yield put(resetLineworkFileAction());

  } catch (error) {
    yield put(setLineworkCreateError(error.message));
  }
}


export function* deleteLineworkHandler(action) {
  try {
    const map = window.map;
    const selectedProjectuuId = yield select((state) => state.selectedProject.uuid);
    const uuid  = action?.payload?.uuid;

    const baseId = 'linework';
    const sourceId = `${baseId}-source-${uuid}`;
    const pointLayerId = `${baseId}-point-${uuid}`;
    const lineLayerId = `${baseId}-line-${uuid}`;
    const polygonLayerId = `${baseId}-polygon-${uuid}`;

    if (map.getLayer(pointLayerId)) {
      map.removeLayer(pointLayerId);
    }
    if (map.getLayer(lineLayerId)) {
      map.removeLayer(lineLayerId);
    }
    if (map.getLayer(polygonLayerId)) {
      map.removeLayer(polygonLayerId);
    }
    if (map.getSource(sourceId)) {
      map.removeSource(sourceId);
    }

    yield call(deleteLineworkKLRequest, uuid);
    const response = yield call(getLineworkListRequest, selectedProjectuuId);
    const lineworkList = response?.data?.data;
    yield put(resetDeleteLineworkAction());
    yield put(setLineworkListAction(lineworkList));
  } catch (e) {
    console.log('error', e);
  }
}
