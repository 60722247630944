import { Switch } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import LoginPage from "../pages/LoginPage";
import PrivateRoute from "./PrivateRoute";
import ProjectPage from "../pages/ProjectPage";
import FlightPage from "../pages/FlightPage";
import FlightAddPage from "../pages/FlightAddPage";
import SeePage from "../pages/SeePage";
import Layout from "../components/Layout";
import MaterialPage from "../pages/MaterialPage";
import MaterialAddPage from "../pages/MaterialAddPage";
import MaterialEditPage from "../pages/MaterialEditPage";
import ExportPage from "../pages/ExportPage";
// import DesignPage from "../pages/DesignPage";
import DesignAddPage from "../pages/DesignAddPage";
import MeasurementPage from "../pages/MeasurementPage";
import MeasurementAddPage from "../pages/MeasurementAddPage";
import DrawToolContextProvider from "../utils/layers/draw/DrawToolContext";
import MeasurementEditPage from "../pages/MeasurementEditPage";
import SmartTrackingPage from "../pages/SmartTrackingPage";
import SmartTrackingAddPage from "../pages/SmartTrackingAddPage";
import SmartTrackingEditPage from "../pages/SmartTrackingEditPage";
import SignupPage from "../pages/SignupPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import ResetPasswordConfirmPage from "../pages/ResetPasswordConfirmPage";
import EmailVerifyPage from "../pages/EmailVerifyPage";
import DesignListPage from "../pages/DesignListPage";
import AccountPage from "../pages/Account";

import InvoicePage from "../pages/Account/InvoicePage";
import DownloadReportPage from "../pages/Report/DownloadReportPage";
import DisplayReport from "../pages/Report/DisplayReport";
import StockpileReportPage from "../pages/Report/StockpileReport";
import SupportPage from "../pages/Support/SupportPage";
import LineworkListPage from "../pages/LineworkListPage";
import LineworkCreatePage  from "../pages/LineworkCreatePage";



const RouteList = () => (
  <Switch>
       <PrivateRoute
          exact
          path="/displayReport"
          component={DisplayReport}
        />
      <PrivateRoute
          exact
          path="/stockpileReport"
          component={StockpileReportPage}
        />
    <PublicRoute exact path="/" component={LoginPage} />
    <PublicRoute exact path="/signup" component={SignupPage} />
    <PublicRoute exact path="/reset-password" component={ResetPasswordPage} />
    <PublicRoute
      exact
      path="/password-reset-confirm/:uid"
      component={ResetPasswordConfirmPage}
    />
    <PublicRoute exact path="/verify-email/:key" component={EmailVerifyPage} />
    <Layout>
      <PrivateRoute exact path="/project" component={ProjectPage} />
      <PrivateRoute exact path="/flight" component={FlightPage} />
      <PrivateRoute exact path="/flight/add" component={FlightAddPage} />
      <PrivateRoute exact path="/see" component={SeePage} />
      <PrivateRoute exact path="/material" component={MaterialPage} />
      <PrivateRoute exact path="/material/add" component={MaterialAddPage} />
      <PrivateRoute exact path="/material/edit" component={MaterialEditPage} />
      <PrivateRoute exact path="/export" component={ExportPage} />
      <PrivateRoute exact path="/design" component={DesignListPage} />
      <PrivateRoute exact path="/design/add" component={DesignAddPage} />
      <PrivateRoute exact path="/report" component={DownloadReportPage} />
      <PrivateRoute exact path="/account" component={AccountPage} />
      <PrivateRoute exact path="/support" component={SupportPage} />
      <PrivateRoute exact path="/linework" component={LineworkListPage} />
      <PrivateRoute exact path="/linework/add" component={LineworkCreatePage} />

      <PrivateRoute exact path="/account/invoice" component={InvoicePage} />


      {/* <PrivateRoute exact path="/design/list" component={DesignListPage} /> */}
      <DrawToolContextProvider>
        <PrivateRoute exact path="/measure" component={MeasurementPage} />
        <PrivateRoute
          exact
          path="/measure/add"
          component={MeasurementAddPage}
        />
        <PrivateRoute
          exact
          path="/measure/edit"
          component={MeasurementEditPage}
        />
        <PrivateRoute
          exact
          path="/smart-tracking"
          component={SmartTrackingPage}
        />
        <PrivateRoute
          exact
          path="/smart-tracking/add"
          component={SmartTrackingAddPage}
        />
        <PrivateRoute
          exact
          path="/smart-tracking/edit"
          component={SmartTrackingEditPage}
        />
      </DrawToolContextProvider>
    </Layout>
  </Switch>
);

export default RouteList;
