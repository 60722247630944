import { setMapLayerLayoutProperty } from "../../../../utils/layers/mapUtils";
import { put, select } from "redux-saga/effects";
import { getRasterLayersAction } from "../../../slices/see/list";
import { getMeasurementListAction } from "../../../slices/measurement/list";
import { getSmartTrackingPolygonListAction } from "../../../slices/smartTracking/list";
import { rearrangeLayersAfterRefreshAction } from "../../../slices/layers";
import { delay } from "../../helpers/layersHelper";
import store from "../../../store";
import { createRasterLayerAction } from "../../../slices/layers/rasterLayersSlice";
import {
  createMeasurementLayerAction,
  createSmartTrackingPolygonLayers,
} from "../../../slices/layers/geoJsonLayersSlice";

export function* hideAllLayersHandler(action) {
  try {
    const layers = window.map
      .getStyle()
      .layers.filter((layer) => layer.id.includes("layer-user"));
    layers.forEach((layer) =>
      setMapLayerLayoutProperty(layer.id, "visibility", "none")
    );
  } catch (error) {
    console.log(error);
  }
}

export function* retainMapStateAfterRefreshHandler() {
  try {
    const selectedFlightId = yield select((state) => state.flightSelected.id);
    if (selectedFlightId) {
      // see layers
      const seeList = yield select((state) => state.seeList.data);
      yield put(createRasterLayerAction({ data: seeList }));

      // Measurements
      const measurements = yield select((state) => state.measurementList.data);
      yield put(createMeasurementLayerAction({ data: measurements }));

      // Polygons
      const polygons = yield select((state) => state.smartTrackingList.data);
      yield put(createSmartTrackingPolygonLayers({ data: polygons }));
      yield put(rearrangeLayersAfterRefreshAction());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* rearrangeLayersAfterRefreshHandler() {
  const RETRY_INTERVAL = 1000; // 1 second
  const MAX_RETRY_TIME = 15000; // 15 seconds

  let elapsedTime = 0;

  while (elapsedTime < MAX_RETRY_TIME) {
    try {
      const selectedFlightId = yield select((state) => state.flightSelected.id);
      const rasterLayers = yield select((state) => state.rasterLayers);
      const geojsonLayers = yield select((state) => state.geoJsonLayers);
      const sortedRasterLayers = Object.keys(rasterLayers)
        .filter((layerKey) => layerKey.includes(selectedFlightId))
        .sort((a, b) => rasterLayers[a].order - rasterLayers[b].order);
      const sortedGeoJsonLayers = Object.keys(geojsonLayers)
        .filter((layerKey) => layerKey.includes(selectedFlightId))
        .sort((a, b) => geojsonLayers[a].order - geojsonLayers[b].order);
      const sortedLayers = sortedRasterLayers.concat(sortedGeoJsonLayers);
      const layers = [];

      for (const layerId of sortedLayers) {
        const layer = window.map.getLayer(layerId);
        if (layer) {
          layers.push(layer);
        } else {
          console.error("Layer not found:", layerId);
        }
      }

      if (layers.length === sortedLayers.length) {
        sortedLayers.forEach(
          (layerId) =>
            window.map &&
            window.map.moveLayer(layerId, sortedLayers[sortedLayers.length - 1])
        );
        return; 
      } else {
        yield delay(RETRY_INTERVAL);
        elapsedTime += RETRY_INTERVAL;
      }
    } catch (error) {
      console.log(error);
      return; 
    }
  }
  console.error('Failed to rearrange layers within the allowed time');
}