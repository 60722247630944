import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import {getIconComponentByType, getIconComponentByTypeWithProps} from "../../utils/assets";
import IconButton from "@mui/material/IconButton";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Slider from "@mui/material/Slider";
import {getMapBoxResourceId} from "../../utils/layers/mapUtils";
import {useDispatch, useSelector} from "react-redux";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import scale from "../../images/scale.png"
import {
  updateRasterLayerTransparencyAction,
  updateRasterLayerVisibilityAction
} from "../../redux/slices/layers/rasterLayersSlice";


const AvatarStyled = styled(Avatar)(({theme}) => ({
  height: "100%",
  width: "100%"
}))

const CardHeaderStyled = styled(CardHeader)(({theme}) => ({
  "& .MuiCardHeader-avatar": {
    marginRight: "5px"
  }
}))

const CardContentStyled = styled(CardContent)(({theme}) => ({
  padding: "0 16px",
  "&:last-child": {
    paddingBottom: 0
  }
}))


const SeeItem = ({layerData, layerKey, provided}) => {
  const dispatch = useDispatch()

  const [defaultValue, setDefaultValue] = useState(layerData.paint["raster-opacity"]);

  const selectFlightId = useSelector(state => state.flightSelected.id)

  const toggleLayer = () => {
    const visibility = layerData.layout.visibility === "none" ? "visible" : "none"
    dispatch(updateRasterLayerVisibilityAction({key: layerKey, visibility: visibility}))
    window.map && window.map.setLayoutProperty(layerKey, "visibility", visibility)
  }

  const setTransparency = (e, newValue) => {
    const name = getMapBoxResourceId(layerData.name, selectFlightId, true)
    const newOpacityValue = parseInt(newValue, 10) / 100
    window.map && window.map.setPaintProperty(name, 'raster-opacity', newOpacityValue);
  }

  const commitChanges = (e, newValue) => {
    const newOpacityValue = parseInt(newValue, 10) / 100
    dispatch(updateRasterLayerTransparencyAction({key: layerKey, opacity: newOpacityValue}))
  }

  return (
    <Card raised
    >
      <CardHeaderStyled
        sx={{
          padding: "3px 5px 3px 16px",
        }}
        avatar={
          <AvatarStyled sx={{bgcolor: "#fff", color: "#000"}}
                        {...provided.dragHandleProps}
          >
            {getIconComponentByType("dragIndicator")}
          </AvatarStyled>
        }
        action={
          <IconButton onClick={toggleLayer}>
            {layerData.layout.visibility === "visible" ? getIconComponentByTypeWithProps("eyeOpen")({
              color: "primary"
            }) : getIconComponentByType("eyeClose")}
          </IconButton>
        }
        title={<Typography>{layerData.title}</Typography>}
      />
      <CardContentStyled>
        <Stack spacing={2} direction="row" sx={{mb: 1}} alignItems="center">
          {getIconComponentByTypeWithProps("imageIcon")({color: "disabled", fontSize: "small"})}
          <Slider
            size="small"
            aria-labelledby={`continuous-slider-${layerKey}`}
            onChange={setTransparency}
            onChangeCommitted={commitChanges}
            defaultValue={defaultValue * 100}
            valueLabelDisplay={'auto'}
          />
          {getIconComponentByTypeWithProps("imageIcon")({
            fontSize: "small"
          })}
        </Stack>

        {(layerData.name === "user-design-heatmap" || layerData.name === "user-heatmap") &&
        <Box pb={1} display="flex" flexDirection="column">
          <Box display="inline-flex" justifyContent="space-between" paddingLeft="auto" paddingRight="auto" pb={1}>
            <Typography sx={{paddingLeft: "24px"}}>Cut</Typography>
            <Typography sx={{paddingRight: "24px"}}>Fill</Typography>
          </Box>
          <img src={scale} style={{
            height: "100%",
            width: "100%"
          }
          }/>
        </Box>
        }

      </CardContentStyled>
    </Card>
  )
}

export default SeeItem