import { useForm } from "../../../hooks/useForm";
import { useSnackbar } from "notistack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "../../Common/TextField";
import FileDropBox from "../../Common/FileDropBox";
import { styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import HelpIcon from "@mui/icons-material/Help";
import Link from "@mui/material/Link";
import SubmitResetButton from "../../Common/SubmitResetButton";
import {
  checkRawFlightToRemoveAction,
  createRawFlightAction,
  downloadSampleGCPFileAction,
  gcpFileDropAction,
  rawFilesDropAction,
} from "../../../redux/slices/flight/create";
import { useDispatch, useSelector } from "react-redux";
import isNull from "lodash/isNull";
import PublicAPI from "../../../utils/API/PublicAPI";
import proj4 from "proj4";
import { v4 } from "uuid";
import { useHistory } from "react-router-dom";
import { removeRawFlightLayersAction } from "../../../redux/slices/layers/flightUploadLayers";
import { resetFlightDuplicateNameAction } from "redux/slices/flight/create";



const ExpandMore = styled((props) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const GridItem = styled(Grid)(({ theme }) => ({
  width: "100%",
}));

const initialValues = {
  flight_name: "",
  raw_files: null,
  epsg_code: "",
  gcp_file: null,
};

const RawFlight = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const loading = useSelector((state) => state.flightCreate?.rawFlight?.loading);
  const flightDuplicateName = useSelector((state) => state.flightCreate?.flightDuplicateName);
  const flightCenter = useSelector((state) => state?.flightUploadLayers);
  const [resetFileCount, setResetFileCount] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
   if (flightDuplicateName) {
      dispatch(resetFlightDuplicateNameAction());
    }  
  },[]);

  useEffect(() => {
    setValues((prevState) => ({
      ...prevState,
      flightUuid: v4(),
    }));
    return () => {
      dispatch(removeRawFlightLayersAction());
      dispatch(checkRawFlightToRemoveAction());
    };
  }, []);

  const reset = () => {
    dispatch(checkRawFlightToRemoveAction());
    dispatch(removeRawFlightLayersAction());
    resetForm();
  };

  const handleSubmit = () => {
    if (validate()) {
      enqueueSnackbar("uploading", { persist: true, preventDuplicate: true });
      dispatch(createRawFlightAction({ ...values, history: history, flightCenter }));
      setResetFileCount(true);
      setValues({ gcp_file: null });
      setTimeout(() => setResetFileCount(false), 0);
      }
  };

  const handleSampleCSVDownload = () => {
    dispatch(downloadSampleGCPFileAction());
  };

  async function handleEPSGCode(e) {
    if (e.target.value.length > 0) {
      const response = await PublicAPI.request({
        method: "get",
        url: `https://epsg.io/${e.target.value}.proj4`,
      });
      if (response.data.includes("DOCTYPE html")) {
        setErrors((prevState) => ({
          ...prevState,
          epsg_code: "Invalid Epsg",
        }));
      } else {
        if (response.data) {
          setErrors((prevState) => ({
            ...prevState,
            epsg_code: "",
          }));
          proj4.defs(`EPSG:${e.target.value}`, response.data);
        } else {
          setErrors((prevState) => ({
            ...prevState,
            epsg_code: "Invalid Epsg",
          }));
        }
      }
    } else {
      setErrors((prevState) => ({
        ...prevState,
        epsg_code: "",
      }));
    }
  }

  const checkFile = (epsg, file) => {
    try {
    if (file.type !== 'text/csv') {
      setErrors({ gcp_file: 'Please upload .csv file.' });
      return;
    }
      const reader = new FileReader();
  
      reader.onload = (e) => {
        const text = e.target.result;
  
  const lines = text.split("\n");
  
  // Extract and validate the headers
  const headersFields = lines[0].split(",");
  if (
    headersFields[0] !== "PointId" ||
    headersFields[1] !== "Northing" ||
    headersFields[2] !== "Easting" ||
    headersFields[3] !== "Elevation"
  ) {
    console.error("Invalid CSV file format or headers.");
    return;
  }
  
  const incorrectData = [];
  
  for (let i = 1; i < lines.length; i++) {
    if (!lines[i].trim()) {
      continue;
    }
    const row = lines[i].split(",");
    
    if (row.length < 4) {
      console.error(`Invalid data in row ${i + 1}: ${lines[i]}`);
      setErrors( { gcp_file : 'Invalid data in gcp file.' });
      continue; 
    }
  
    const pointId = row[0]; 
    const northing = parseFloat(row[1]); 
    const easting = parseFloat(row[2]);
    const elevation = parseFloat(row[3]); 
  
      if (
        isNaN(pointId) ||
        isNaN(northing) ||
        isNaN(easting) ||
        isNaN(elevation)
      ) {
        incorrectData.push({
          PointId: pointId,
          Northing: northing,
          Easting: easting,
          Elevation: elevation,
        })
        console.error(`Invalid data in row ${i + 1}: ${lines[i]}`);
        continue; 
      }
      }
  
        if(incorrectData.length) {
          setErrors( { gcp_file : 'Please correct GCP file' });
          return;
    
        }
        else {
          dispatch(gcpFileDropAction({ epsg, file }));
        }
        
      };
      reader.readAsText(file);
    } catch (error) {
      console.log(error);
    }
  }

  const handleGCPFile = (files) => {
    if (values.epsg_code.length < 1) {
      setErrors((prevState) => ({
        ...prevState,
        epsg_code: "Please Provide Epsg First",
      }));
      return;
    }
    setValues((prevState) => ({
      ...prevState,
      gcp_file: files[0],
    }));
     checkFile(values.epsg_code, files[0]);
  };

  const handleRawFiles = async (files) => {
    try {
      setErrors({});
      await dispatch(rawFilesDropAction({ files, flightUuid: values.flightUuid }));

      setValues((prevState) => ({
        ...prevState,
        raw_files: files,
      }));
    } catch (error) {
      console.error('Error handling raw files:', error);
    }

 
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("flight_name" in fieldValues)
      temp.flight_name = fieldValues.flight_name
        ? ""
        : "This field is required.";
    if ("raw_files" in fieldValues)
      temp.raw_files = fieldValues.raw_files ? "" : "This field is required.";
//  if ("gcp_file" in fieldValues)
//       temp.gcp_file = fieldValues.gcp_file ? "" : "This field is required.";


    if (isNull(values.gcp_file)) temp.epsg_code = "";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, errors, setErrors, handleInputChange, resetForm, setValues } =
    useForm(initialValues, validate, true);

  return (
    <Box pt={2} m={1}>
      <Grid container direction="column" spacing={1}>
        <GridItem item>
          <TextField
            label="Flight Name"
            fullWidth
            required
            name="flight_name"
            value={values.flight_name}
            error={errors.flight_name || !!flightDuplicateName}
            onChange={handleInputChange}
          />
        </GridItem>
        <GridItem item>
          <FileDropBox
            label="New Raw Flight"
            acceptFileType="image/*"
            onFileDrop={handleRawFiles}
            name="raw_files"
            error={errors.raw_files}
            multiple={true}
            value={values.raw_files}
            resetFileCount={resetFileCount} 
          />
        </GridItem>
        <GridItem item>
          <Card variant={expanded ? "standard" : "outlined"}>
            <CardActions>
              <Typography>Upload GCP File</Typography>
              <HelpIcon fontSize="12" style={{ marginLeft: 5 }} />
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                {expanded ? <RemoveIcon /> : <AddIcon />}
              </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent
                sx={{
                  padding: 0,
                }}
              >
                <Grid container direction="column" spacing={1}>
                  <GridItem item>
                    <Box width="100%">
                      <Box style={{display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="subtitle1" style={{align: 'left'}}>
                          GCP template File
                        </Typography>
                        <Link
                          variant="subtitle1"
                          align="right"
                          sx={{
                            marginRight: 1,
                          }}
                          onClick={handleSampleCSVDownload}
                        >
                          Download
                        </Link>
                      </Box>
                    </Box>
                  </GridItem>
                  <GridItem item>
                    <TextField
                      label="EPSG Code*"
                      fullWidth
                      required
                      name="epsg_code"
                      value={values.epsg_code}
                      error={errors.epsg_code}
                      onChange={handleInputChange}
                      onBlur={handleEPSGCode}
                    />
                  </GridItem>
                  <GridItem item>
                    <FileDropBox
                      label="Ground Control Points"
                      acceptedFiles=".csv, text/csv, application/csv, text/x-csv, application/x-csv"
                      message="csv file only"
                      onFileDrop={handleGCPFile}
                      name="gcp_file"
                      error={errors.gcp_file}
                      is_file_accepted={!isNull(values.gcp_file)}
                      value={!isNull(values.gcp_file)}
                    />
                  </GridItem>
                </Grid>
              </CardContent>
            </Collapse>
          </Card>
        </GridItem>
      <GridItem item>
        <SubmitResetButton
          resetForm={reset}
          handleSubmit={handleSubmit}
          loading={loading}
        />
      </GridItem>
      </Grid>
    </Box>
  );
};

export default RawFlight;
