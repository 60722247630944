import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Grid from "@mui/material/Grid";
import SeeItem from "./SeeItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { rearrangeRasterLayersAction } from "redux/slices/layers/rasterLayersSlice";
import { setWmsOpacity } from "redux/slices/flight/list";
import Card from "@mui/material/Card";
import {getIconComponentByType, getIconComponentByTypeWithProps} from "../../utils/assets";
import IconButton from "@mui/material/IconButton";
import {styled} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CardHeader from "@mui/material/CardHeader";
import React, { useState } from 'react';


const AvatarStyled = styled(Avatar)(({theme}) => ({
  height: "100%",
  width: "100%"
}))

const CardHeaderStyled = styled(CardHeader)(({theme}) => ({
  "& .MuiCardHeader-avatar": {
    marginRight: "5px"
  }
}))

const SeeList = () => {
  const dispatch = useDispatch();
  const selectFlightId = useSelector(state => state.flightSelected.id);
  const [wmsVisibility, setWmsVisibility] = useState(true); 
  const userEmail = localStorage.getItem("userEmail");


  const rasterLayers = useSelector(state => {
    let unsortedLayers = {};
    Object.keys(state.rasterLayers).forEach(layerKey => {
      if (state.rasterLayers[layerKey].showInLayerSwitcher === true && layerKey.includes(selectFlightId)) {
        unsortedLayers[layerKey] = state.rasterLayers[layerKey];
      }
    });
    let layersArray = [];
    for (const layer in unsortedLayers) {
      layersArray.push({ name: layer, data: unsortedLayers[layer] });
    }
    layersArray = layersArray.sort((a, b) => b.data.order - a.data.order);
    return layersArray;
  });

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const destinationLayer = rasterLayers[result.destination.index].name;
    const sourceLayer = rasterLayers[result.source.index].name;
    dispatch(rearrangeRasterLayersAction({ sourceLayer, destinationLayer }));
  };


    const toggledWmsLayer = () => {
    setWmsVisibility(!wmsVisibility);

        window.map.setLayoutProperty('wms-layer-1', 'visibility', !wmsVisibility ? 'visible' : 'none');
        window.map.setLayoutProperty('wms-layer-2', 'visibility', !wmsVisibility ? 'visible' : 'none');
    }

  const handleOpacity = (e) => {
      dispatch(setWmsOpacity(parseFloat(e.target.value)));
  };
  return (
    <>
      <Box alignItems="center" display="flex" flexDirection="column" pt={2} pb={2}>
        <Typography variant="h4">See</Typography>
      </Box>
      <Divider />
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="rasterLayers">
          {(provided) => (
            <Grid
              container
              {...provided.droppableProps}
              ref={provided.innerRef}
              spacing={1}
              sx={{ padding: "8px 8px 8px" }}
            >
              {rasterLayers.length > 0 && window.map ? (
                rasterLayers.map((rasterLayer, index) => (
                  <Draggable key={rasterLayer.name} draggableId={rasterLayer.name} index={index}>
                    {(provided) => (
                      <Grid item container {...provided.draggableProps} ref={provided.innerRef}>
                        <Grid item xs>
                          <SeeItem
                            layerData={rasterLayer.data}
                            layerKey={rasterLayer.name}
                            provided={provided}
                          />
                        </Grid>
                      </Grid>
                    )}

                  </Draggable>
                ))
              ) : (
                <></>
              )}
              {provided.placeholder}
              { userEmail === 'lce@hillplain.com' ? //lce
               <Card style={{width: '98%', marginTop: '5px', marginLeft: '5px'}} raised>
                  <CardHeaderStyled
                    sx={{
                      padding: "3px 5px 3px 16px",
                    }}
                    avatar={
                      <AvatarStyled sx={{bgcolor: "#fff", color: "#000"}}
                                    {...provided.dragHandleProps}
                      >
                        {getIconComponentByType("dragIndicator")}
                      </AvatarStyled>
                    }
                    action={
                      <IconButton onClick={toggledWmsLayer}>
                        {wmsVisibility ? getIconComponentByTypeWithProps("eyeOpen")({
                          color: "primary"
                        }) : getIconComponentByType("eyeClose")}
                      </IconButton>
                    }
                    title={<Typography>WMS Layers</Typography>}
                  />
                </Card>
              : ''}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default SeeList;
