import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    loading: false,
    serverErrors: {},
    first_name: "",
    last_name: "",
  },
  reducers: {
    googleLoginAction() {
      // Your logic for Google login action
    },
    loginAction(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    setLoginServerErrorsAction(state, action) {
      return {
        ...state,
        serverErrors: action.payload.errors,
        loading: false,
      };
    },
    setUsernameAction(state, action) {
      return {
        ...state,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
      };
    },
    clearServerErrorsAction(state) {
      return {
        ...state,
        serverErrors: {},
      };
    }
  },
});

export const {
  googleLoginAction,
  loginAction,
  setLoginServerErrorsAction,
  setUsernameAction,
  clearServerErrorsAction,
} = loginSlice.actions;

export default loginSlice.reducer;
