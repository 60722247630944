import { call, put, select } from "redux-saga/effects";
import {
  createMeasurementProfileRequest,
  createMeasurementRequest,
  deleteMeasurementRequest,
  editMeasurementRequest,
  getMeasurementListRequest,
} from "../requests/measurements";
import { resetCreateMeasureAction } from "../../slices/measurement/create";
import {
  getMeasurementListAction,
  handleMeasurementListDataAction,
  setMeasurementListDataAction,
resetMeasurementFiltersAction
} from "../../slices/measurement/list";
import {
  createMeasurementLayerAction,
  updateGeoJsonLayerVisibilityAction,
} from "../../slices/layers/geoJsonLayersSlice";
import { getGeoJsonLayerBase } from "../../../utils/layers/initialGeoJsonLayers";
import {
  getMapBoxResourceId,
  setMapLayerLayoutProperty,
} from "../../../utils/layers/mapUtils";
import store from "../../store";
import isNull from "lodash/isNull";
import { downloadMeasurementGeoJsonHelper } from "../helpers/measurementHelper";
import { editMeasurementSuccessAction } from "../../slices/measurement/edit";
import { measurementDeleteSuccessAction } from "../../slices/measurement/delete";
import {
  formatMeasurementProfileDataAction,
  setMeasurementProfileDataAction,
} from "../../slices/measurement/profiler";

export function* createMeasurementHandler(action) {
  const history = action.payload.history;
  delete action.payload.history;
  const drawTool = action.payload.drawTool;
  delete action.payload.drawTool;
  try {
    const geojson = yield select(
      (state) => state.measurementCreate.geojson.geometry
    );
    const measurementType = yield select(
      (state) => state.measurementCreate.type
    );
    const flightId = yield select((state) => state.flightSelected.id);
    let type;

    if (measurementType === "Point") {
      type = 0;
    } else if (measurementType === "Polyline") {
      type = 1;
    } else {
      type = 2;
    }

    const createMeasure =  yield call(createMeasurementRequest, {
      ...action.payload,
      type: type,
      geometry: geojson,
      flight: flightId,
    });
    drawTool.finishGeometry();

    yield put(resetCreateMeasureAction());
    yield put(resetMeasurementFiltersAction());
    history.push("/measure");
  } catch (error) {
    yield put(resetCreateMeasureAction());
    history.push("/measure");
    drawTool.finishGeometry();
    console.log(error);
  }
}

export function* getMeasurementListHandler() {
  try {
    const selectedFlightId = yield select((state) => state.flightSelected.id);
    const response = yield call(getMeasurementListRequest, selectedFlightId);
    yield put(createMeasurementLayerAction({ data: response.data, selectedFlightId }));
    yield put(handleMeasurementListDataAction({ data: response.data, selectedFlightId }));
  } catch (error) {
    console.log(error);
  }
}

export function* handleMeasurementListDataHandler(action) {
  try {
    let data = action.payload.data;

    const filters = yield select((state) => state.measurementList.filters);
    const baseGeoJsonLayerData = getGeoJsonLayerBase("measurement");
    let geoJsonLayersKeys = Object.keys(
      yield select((state) => state.geoJsonLayers)
    );
    const selectedFlightId = yield select((state) => state.flightSelected.id);

    let showLayerId = [];
    let filterData = [];
    let newData = [];
    let showLayerIds = [];
    const geomTypes = ["Point", "LineString", "Polygon"];

    if (Array.isArray(filters?.type) && filters.type.length > 0) {
      for (const filterType of filters.type) {
        if (filterType === "all") {
          continue;
        }
        if(data && filters?.type !== "all" ) {
          filterData = data.filter((item) => item.geometry.type === filterType);
        }
        else {
          filterData = data;
        }
        newData.push(...filterData);
        let showLayerId = getMapBoxResourceId(
          `${baseGeoJsonLayerData.name}-${filterType}`,
          selectedFlightId,
          true
        );

        showLayerIds.push(showLayerId);
        
        
      }
    }
    else { 
      geomTypes.forEach(type => {

        showLayerId = getMapBoxResourceId(
          `${baseGeoJsonLayerData.name}-${type}`,
          selectedFlightId,
          true
        );
        showLayerIds.push(showLayerId);
      });
    }
    if (data) {
      if (filters.sort === "nameAsc") {
        data = data.slice().sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));
      } else if (filters.sort === "nameDsc") {
        data = data.slice().sort((a, b) => b.name.localeCompare(a.name, undefined, { numeric: true, sensitivity: 'base' }));
      } else if (filters.sort === "dateAsc") {
        data = data
          .slice()
          .sort((a, b) =>
            new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
          );
      } else if (filters.sort === "dateDsc") {
        data = data
          .slice()
          .sort((a, b) =>
            new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
          );
      }
    }
   
    if (!isNull(showLayerId)) {
      geoJsonLayersKeys.forEach((layerId) => {
        const isVisible = showLayerIds.includes(layerId);
  
        if (setMapLayerLayoutProperty(layerId, "visibility", isVisible ? "visible" : "none")) {
          store.dispatch(
            updateGeoJsonLayerVisibilityAction({
              key: layerId,
              visibility: isVisible ? "visible" : "none",
            })
          );
        }
      });
    }
    yield put(
      setMeasurementListDataAction({
        data: data,
        filterData: data,
      })
    );
  } catch (error) {
    console.log(error);
  }
}

export function* createSocketConnectionHandler() {
  try {
    console.log("called socket");
  } catch (error) {
    console.log(error);
  }
}

export function* updateMeasureDataListHandler(action) {
  try {
    const measurementListData = yield select(
      (state) => state.measurementList.data
    );
    const updatedData = measurementListData.filter(
      (measurementData) => measurementData.id !== action.payload.data.id
    );
    yield put(
      handleMeasurementListDataAction({
        data: [...updatedData, action.payload.data],
      })
    );
  } catch (error) {
    console.log(error);
  }
}

export function* downloadMeasurementGeoJsonHandler(action) {
  try {
    const geoJson = yield call(
      downloadMeasurementGeoJsonHelper,
      action.payload.data
    );
    const element = document.createElement("a");
    const flightName = yield select((state) => state.flightSelected.name);
    let filename =
      flightName +
      "_" +
      action.payload.data.name +
      "_measurement_geometry.geojson";
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," +
        encodeURIComponent(JSON.stringify(geoJson, undefined, 2))
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  } catch (error) {
    console.log(error);
  }
}

export function* editMeasurementHandler(action) {
  try {
    const history = action.payload.history;
    delete action.payload.history;
    const drawTool = action.payload.drawTool;
    delete action.payload.drawTool;
    const geojson = yield select((state) => state.measurementEdit.geojson);
    const selectedMeasurementId = yield select(
      (state) => state.measurementEdit.data.id
    );
    const payload = {
      name: action.payload.name,
      label: action.payload.label,
      color: action.payload.color,
      properties: {},
      processed: false,
      geometry: geojson.geometry,
    };
    const response = yield call(
      editMeasurementRequest,
      selectedMeasurementId,
      payload
    );
    yield put(editMeasurementSuccessAction());
    drawTool.finishGeometry();
    history.push("/measure");
  } catch (error) {
    console.log(error);
  }
}

export function* measurementDeleteHandler(action) {
  try {
    yield call(deleteMeasurementRequest, action.payload.id);
    yield put(measurementDeleteSuccessAction());
    yield put(getMeasurementListAction());
  } catch (error) {
    console.log(error);
  }
}

export function* createMeasurementProfileHandler(action) {
  try {
    console.log();
  } catch (error) {
    console.log(error);
  }
}

export function* formatMeasurementProfileDataHandler(action) {
  try {
    const response = [];
    if (action.payload.data) {
      for (const key in action.payload.data) {
        for (const counter in action.payload.data[key]) {
          if (
            key === "current_flight_elevation" &&
            action.payload.data[key].length > 0
          ) {
            response[counter] = {
              name: action.payload.data["distance_lst"][counter],
              current_flight_elevation: action.payload.data[key][counter],
            };
          } else if (
            key === "previous_flight_elevation" &&
            action.payload.data[key].length > 0
          ) {
            response[counter]["previous_flight_elevation"] =
              action.payload.data[key][counter];
          } else if (
            key === "current_flight_design_elevation" &&
            action.payload.data[key].length > 0
          ) {
            response[counter]["flight_design"] =
              action.payload.data[key][counter];
          }
        }
      }
      yield put(
        setMeasurementProfileDataAction({
          data: response,
          latLon: action.payload.data["latlon_list"],
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}
